import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@delon/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subsidiary-dashboard',
  templateUrl: './subsidiary-dashboard.component.html',
  styles: []
})
export class SubsidiaryDashboardComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.settingsService.user.deptType === 1) {
      // 集团用户，跳转到集团总览页面
      this.router.navigate(['dashboard']);
    } else if (this.settingsService.user.deptType === 2) {
      // 门店用户，跳转到门店总览页面
      this.router.navigate(['subsidiary/dashboard']);
    }
  }

}
