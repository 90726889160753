import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzCascaderOption } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper, SettingsService } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-user-edit',
  templateUrl: './edit.component.html',
})
export class AdminUserEditComponent implements OnInit {
  i: any;
  data: any = {
    "username": "",
    "mobile": "",
    "deptId": "",
    "roleIdList": [],
    "password": ""
  }
  depts: string;
  // expandKeys: any[] = [];
  nzOptions: NzCascaderOption[] = [];
  roleId: any = null; // 选中角色
  roleOptions: any = [];
  loading = false;
  userNameFlag = false; // 用户名重名检测标识
  userName = ''; // 编辑用户名

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private modalHelper: ModalHelper,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    public settingsService: SettingsService,
  ) { }

  ngOnInit(): void {
    if (this.i.id !== 0) {
      this.getInfo();
    }
    if (this.settingsService.user.deptType === 1) {
      // 集团用户显示部门列表
      this.getDeptList();
    } else {
      // 门店显示所属公司
      this.depts = this.settingsService.user.deptId;
    }
    this.getRoleList();
  }

  // 获取信息
  getInfo() {
    this.loading = true;
    this.http.get(`/sys/user/info/${this.i.userId}`).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.user) {
        this.data = res.user;
      }
      if (this.data.deptId) {
        this.depts = this.data.deptId.toString();
      }
      if (this.data.roleIdList.length > 0) {
        this.roleId = this.data.roleIdList[0].toString();
      }
      if (this.data.username) {
        this.userName = this.data.username;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }


  // 添加/编辑
  saveOrUpdate() {
    this.loading = true;
    this.data.deptId = this.depts;
    this.data.roleIdList = [];
    if (this.roleId) {
      this.data.roleIdList.push(this.roleId);
    }
    // 添加
    if (this.i.id === 0) {
      this.http.put('/sys/user/save', this.data).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msgSrv.success('保存成功');
          this.modal.close(true);
        } else {
          this.msgSrv.error(res.msg);
          this.modal.close(false);
        }
        this.cdr.detectChanges();
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    } else {
      // 修改
      this.http.post('/sys/user/update', this.data).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msgSrv.success('编辑成功');
          this.modal.close(true);
        } else {
          this.msgSrv.error(res.msg);
          this.modal.close(false);
        }
        this.cdr.detectChanges();
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    }

  }

  // 检测添加用户名是否重名
  checkUserName() {
    if (this.data.username !== this.userName) {
      this.http.get('/sys/user/checkName?name=' + this.data.username).subscribe(res => {
        // tslint:disable-next-line: prefer-conditional-expression
        if (res.code === 500) {
          this.userNameFlag = true;
        } else {
          this.userNameFlag = false;
        }
      }, error => {
        console.log('HTTP Error', error);
      });
    } else {
      this.userNameFlag = false;
    }

  }

  checkName() {
    if (this.data.username === '') {
      this.userNameFlag = false;
    }
  }

  // 获取选中角色列表
  getRoleList() {
    this.loading = true;
    this.http.get('/sys/role/select').subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.msg === 'success') {
        if (res.list) {
          this.roleOptions = res.list;
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取公司列表
  getDeptList() {
    this.loading = true;
    this.http.get('/sys/dept/treeSelect').subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.msg === 'success') {
        if (res.data) {
          this.nzOptions = res.data;
          // 设置联动子叶标识
          this.treeList(this.nzOptions);
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 树
  treeList(data) {
    if (data.length > 0) {
      for (const dept of data) {
        if (dept.children.length === 0) {
          dept.isLeaf = true;
        }
        if (dept.children.length > 0) {
          this.treeList(dept.children);
        }
      }
    }
  }

  close() {
    this.modal.destroy();
  }
}
