import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-role-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class AdminRoleViewComponent implements OnInit {
  loading = false;
  i: any;
  menus: any = []; // 权限列表
  data: any = [];
  groupChecked: any = [];

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.menus = await this.getAuthList();
    this.getRoleInfo();
    this.loading = false;
  }

  // 获取角色信息
  getRoleInfo() {
    this.http.get(`/sys/role/info/${this.i.roleId}`).subscribe(async res => {
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data = res.role;
        // 处理权限列表选中
        this.data.menuIdList.map(item => {
          this.menus.map(group => {
            if (group.value === item) {
              group.checked = true;
              this.groupChecked[group.value] = true;
            } else {
              group.childOptions.map(child => {
                if (child.value === item) {
                  child.checked = true;
                }
              })
            }
          })
        })
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 获取权限菜单列表
  getAuthList() {
    return new Promise((resolve, reject) => {
      this.http.get<any>(`/sys/menu/list`).subscribe(res => {
        this.dialogService.getErrorCode(res);
        if (res.msg === 'success') {
          const menus = new Array();
          // 获取一级菜单列表，type=1
          const menusOne = res.menuList.filter(item => item.type === 1);
          // 循环获取一级菜单的权限子菜单列表
          for (const menu of menusOne) {
            const childMenuOptions: any = [];
            for (const child of res.menuList) {
              if (menu.menuId === child.parentId) {
                childMenuOptions.push({ label: child.name, value: child.menuId, checked: false });
              }
            }
            menus.push({ label: menu.name, value: menu.menuId, checked: false, childOptions: childMenuOptions });
          }
          resolve(menus);
        }
      }, error => {
        resolve(error.message);
      });
    });

  }

  close() {
    this.modal.destroy();
  }
}
