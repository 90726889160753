export const clientConfig = {
  // 公告管理类型
  noticeTypeOption: [
    { value: 1, label: '申报' },
    { value: 2, label: '销项' },
    { value: 3, label: '进项' },
    { value: 4, label: '其他' },
    { value: 5, label: '升级' },
    { value: 6, label: '警告' },
  ],
  // 会计准则
  accountingStandardsOption: [
    { value: '2000020001', label: '企业会计准则' },
    { value: '2000020002', label: '小企业会计准则' },
    { value: '2000020003', label: '企业会计准则（商业银行）' },
    { value: '2000020004', label: '企业会计准则（保险公司）' },
    { value: '2000020005', label: '企业会计准则（证券公司）' },
    { value: '2000020006', label: '企业会计准则（担保企业会计核算办法）' },
    { value: '2000020007', label: '事业单位会计制度' },
  ],
  // 纳税人身份
  vatTaxpayerOption: [
    { value: '2000010001', label: '一般纳税人' },
    { value: '2000010002', label: '小规模纳税人' },
  ],
  // 网报登录方式
  loginMethodOption: [
    { value: 1, label: 'CA证书登录' },
    { value: 2, label: '用户名密码登录' },
    { value: 3, label: '实名制登录' },
    { value: 4, label: '证件登录' },
    { value: 5, label: '手机号验证码登录' },
    { value: 6, label: '手机号密码登录' },

  ],
  // 企业类型
  deptTypeOption: [
    { value: 1, label: '总公司' },
    { value: 2, label: '分公司' },
  ],
};
