import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SettingsService } from '@delon/theme';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import * as format from 'date-fns/format';
import { Router } from '@angular/router';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  searchToggleStatus: boolean;
  curMonth: any = new Date();
  today = new Date();
  globalDate = format(this.curMonth, 'YYYY-MM'); // 全局时间

  // 当前日期之后的禁用
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) > 0;
  };
  constructor(
    public settings: SettingsService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.curMonth = this.settings.layout.globalDate;
  }

  toggleCollapsedSidebar() {
    this.settings.setLayout('collapsed', !this.settings.layout.collapsed);
  }

  searchToggleChange() {
    this.searchToggleStatus = !this.searchToggleStatus;
  }

  // 存储全局时间变量
  globalDateChange() {
    this.globalDate = format(this.curMonth, 'YYYY-MM');
    this.settings.setLayout('globalDate', this.globalDate);
    this.cdr.detectChanges();
    // 重新访问当前页面数据
    this.router.navigateByUrl(this.router.url);
  }
}
