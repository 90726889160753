import { Injectable } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { DialogService } from './dialog.service';
import { CacheService } from '@delon/cache';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  region: any = [];

  constructor(
    private http: _HttpClient,
    private dialogService: DialogService,
    public cache: CacheService,
  ) { }

  setRegion() {
    this.region = this.getRegionList();
  }

  getRegion() {
    return this.region;
  }

  // 地区列表
  getRegionList() {
    return new Promise((resolve, reject) => {
      this.http.get('/sys/district/treeList').subscribe(res => {
        const flag = this.dialogService.getErrorCode(res);
        if (flag) {
          this.cache.set('region',res.data);
          resolve(res.data);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }

  // 已设置地区列表
  getSelectedRegionList() {
    return new Promise((resolve, reject) => {
      this.http.get('/filing/district/treeList').subscribe(res => {
        const flag = this.dialogService.getErrorCode(res);
        if (flag) {
          this.cache.set('selectedRegion',res.data);
          resolve(res.data);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }
}
