import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper, SettingsService } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { AdminOrganizationEditComponent } from './edit/edit.component';
import { AdminOrganizationViewComponent } from './view/view.component';
import { HttpHeaders } from '@angular/common/http';
import { DA_SERVICE_TOKEN, ITokenService, SocialService } from '@delon/auth';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/service/dialog.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-admin-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.less'],
  providers: [SocialService],
})
export class AdminOrganizationComponent implements OnInit {
  rows: any = []; // 组织列表
  status: any = []; // 启用状态列表
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '税号', index: 'taxCode', i18n: 'orgnization.list.taxCode' },
    { title: '公司名称', index: 'name', i18n: 'orgnization.list.name' },
    { title: '公司地址', index: 'address', render: 'address', i18n: 'orgnization.list.address' },
    { title: '负责人', index: 'owner', i18n: 'orgnization.list.owner' },
    { title: '联系电话', index: 'contact', i18n: 'st.mobile' },
    { title: '企业类型', index: 'type', render: 'type', i18n: 'orgnization.list.type' },
    {
      title: '下级公司',
      index: 'childNum',
      render: 'childNum',
      format: (item: any) => `${item.childNum} 个`,
      className: 'item-cnums',
      i18n: 'orgnization.list.childNum'
    },
    { title: '启用状态', index: 'status', render: 'status', acl: { ability: ['sys:dept:enable'] }, i18n: 'st.status' },
    {
      title: '操作',
      className: 'table-actions',
      i18n: 'st.operate',
      buttons: [
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'modal',
          size: 800,
          component: AdminOrganizationViewComponent,
          paramName: 'i',
          acl: { ability: ['sys:dept:info'] },
          click: () => this.getList(),
        },
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          type: 'modal',
          size: 800,
          component: AdminOrganizationEditComponent,
          paramName: 'i',
          acl: { ability: ['sys:dept:update'] },
          click: () => this.getList(),
        },
      ],
    },
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    name: '',
    contact: '',
    parentId: '',
    owner: ''
  };
  pageTotal: any = 0; // 列表记录总数
  parentId: any = 0; // 组织父级ID
  parentIdList = []; // 上级组织id列表
  parentDepts: any[] = []; // 上级组织id和name列表
  parentName: any = '组织管理'; // 组织父级名称
  parent: any = { id: 0, name: '组织管理' }; // 当前组织导航信息
  loading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    public settingService: SettingsService
  ) { }

  ngOnInit() {
    // 获取当前集团组织名称
    if (this.settingService.user.deptName) {
      // tslint:disable-next-line: prefer-conditional-expression
      if (this.settingService.user.username === 'admin') {
        this.parentName = '组织管理';
        this.parentId = 0;
      } else {
        this.parent = { id: this.settingService.user.deptId, name: this.settingService.user.deptName };
        this.parentName = this.settingService.user.deptName;
      }
    }
    this.getList();
  }

  // 组织查询分页列表
  getList() {
    this.loading = true;
    this.http.get('/sys/dept/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 组织搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 获取下级组织列表
  getChilds(deptId, name) {
    this.parentName = name;
    this.parentId = deptId;
    this.queryParams.page = 1;
    this.queryParams.parentId = deptId;
    this.queryParams.name = '';
    this.queryParams.owner = '';
    this.queryParams.contact = '';
    // 处理保存上级组织
    this.parentDepts.push({ id: this.parentId, name: this.parentName });
    this.parentIdList.push(this.parentId);
    this.getList();
  }

  // 获取上级组织列表
  getParentList(parent) {
    const index = this.parentIdList.indexOf(parent.id);
    this.parentDepts = this.parentDepts.slice(0, index - 1);
    this.parentIdList = this.parentIdList.slice(0, index - 1);
    this.queryParams.parentId = parent.id;
    this.queryParams.page = 1;
    this.parentName = parent.name;
    this.parentId = parent.id;
    this.getList();
  }

  // 添加组织
  add() {
    this.parentId = this.queryParams.parentId || 0;
    this.modal.static(AdminOrganizationEditComponent, { i: { id: 0, parentId: this.parentId, parentName: this.parentName } }, 800).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 更新启用状态
  changeStatus(event, deptId) {
    this.loading = true;
    // true 开启
    if (event) {
      this.http.post('/sys/dept/enable?deptId=' + deptId, '').subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msg.info("状态已启用");
          this.getList();
        } else {
          this.st.reload();
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      })
    } else {
      // false 禁用
      this.http.post('/sys/dept/disable?deptId=' + deptId, '').subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msg.info("状态已禁用");
          this.getList();
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      })
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }

}
