import { Injectable } from '@angular/core';
import { resolve } from 'url';
import { HttpClient } from '@angular/common/http';
import { DialogService } from './dialog.service';
import { CacheService } from '@delon/cache';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    public cache: CacheService,
  ) { }

  // 获取当前用户登录信息
  async getUser() {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.http.get('/sys/user/info').subscribe((data: any) => {
        const flag = this.dialogService.getErrorCode(data);
        if (flag) {
          if (data.user !== null) {
            resolve(data.user);
          }
        }
      }, error => {
        resolve(error.message);
      })
    })
  }

  // 获取当前用户权限信息
  getAuth() {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.http.get('/sys/menu/nav').subscribe((data: any) => {
        this.dialogService.getErrorCode(data);
        if (data.msg === 'success') {
          if (data.menuList !== null) {
            resolve(data.menuList);
          }
        }
      }, error => {
        resolve(error.message);
      })
    })
  }
}
