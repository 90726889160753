import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzCascaderOption } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { clientConfig } from './../../../../../environments/client-config';
import { SystemService } from 'src/app/service/system.service';
import { CacheService } from '@delon/cache';
import { DialogService } from 'src/app/service/dialog.service';


@Component({
  selector: 'app-admin-organization-edit',
  templateUrl: './edit.component.html',
  styles: [
    `
      :host ::ng-deep .ant-form-item-label {
        padding-left: 20px;
      }
    `,
  ],
})
export class AdminOrganizationEditComponent implements OnInit {
  i: any;
  data = {
    "parentId": 0,
    "parentName": "",
    "orderNum": 0,
    "name": "",
    "address": "",
    "taxCode": "",
    "owner": "",
    "contact": "",
    "provinceName": "",
    "cityName": "",
    "districtName": "",
    "provinceCode": "",
    "cityCode": "",
    "districtCode": "",
    "thirdLoginAccount": "",
    "thirdLoginMethod": 1,
    "thirdLoginPassword": "",
    "thirdCanChange": "false",
    "thirdFilingPassowrd": "",
    "thirdAccountingStandards": "2000020001",
    "thirdVatTaxpayer": "2000010001",
    "thirdEnabledYear": "",
    "thirdEnabledMonth": "",
    "thirdOrgId": null,
    "type": 1,
    "deptId": ""
  }
  provinceOption: any = [];
  nzOptions: NzCascaderOption[] = [];
  regeion: string[] | null = null; // 所选地区码列表
  regeionName: any[] = []; // 所选地区名称列表
  accountingStandardsOption = clientConfig.accountingStandardsOption;
  vatTaxpayerOption = clientConfig.vatTaxpayerOption;
  loginMethodOption = clientConfig.loginMethodOption;
  treeList: any = []; // 地区原始数据
  deptTypeOption = clientConfig.deptTypeOption;
  loading = false;
  enabledYear: any = new Date(); // 启用年
  enabledMonth: any = new Date(); // 启用月

  // 获取地区名事件
  selectionChange(option) {
    this.regeionName = option.map(i => i.name);
  }

  // 启用年事件
  onYearChange(event) {
    event ? this.data.thirdEnabledYear = event.getFullYear().toString() : this.data.thirdEnabledYear = '';
  }

  // 启用月事件
  onMonthChange(event) {
    event ? this.data.thirdEnabledMonth = (event.getMonth() + 1).toString() : this.data.thirdEnabledMonth = '';
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private modalHelper: ModalHelper,
    private cdr: ChangeDetectorRef,
    private systemService: SystemService,
    private cache: CacheService,
    private dialogService: DialogService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    if (this.i.id !== 0) {
      this.getInfo();
    } else {
      // 添加用户，初始化启用年，启用月
      this.data.thirdEnabledYear = new Date().getFullYear().toString();
      this.data.thirdEnabledMonth = (new Date().getMonth() + 1).toString();
    }
    this.treeList = this.cache.getNone('region');
    if (this.treeList === null) {
      this.treeList = await this.systemService.getRegionList();
    }
    // 获取位置地区信息
    this.nzOptions = this.generateTree(this.treeList);
    this.loading = false;
  }

  // 获取修改公司信息
  getInfo() {
    this.http.get(`/sys/dept/info/${this.i.deptId}`).subscribe(res => {
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data = res.dept;
        // tslint:disable-next-line: prefer-conditional-expression
        if (this.data.provinceCode) {
          this.regeion = [this.data.provinceCode, this.data.cityCode, this.data.districtCode];
        } else {
          this.regeion = [this.data.cityCode, this.data.districtCode];
        }
        const curYear = new Date().getFullYear();
        const curMonth = new Date().getMonth() + 1;
        const curDate = new Date().getDate();
        // tslint:disable-next-line: prefer-conditional-expression
        if (this.data.thirdEnabledYear) {
          this.enabledYear = new Date(this.data.thirdEnabledYear + '-' + curMonth + '-' + curDate);
        } else {
          this.enabledYear = '';
        }
        // tslint:disable-next-line: prefer-conditional-expression
        if (this.data.thirdEnabledMonth) {
          this.enabledMonth = new Date(curYear + '-' + this.data.thirdEnabledMonth + '-' + curDate);
        } else {
          this.enabledMonth = '';
        }
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 添加/编辑公司信息
  save() {
    this.loading = true;
    // 选择直辖市
    if (this.regeion.length! === 2) {
      this.data.provinceCode = '';
      this.data.cityCode = this.regeion[0];
      this.data.districtCode = this.regeion[1];
      this.data.provinceName = '';
      this.data.cityName = this.regeionName[0];
      this.data.districtName = this.regeionName[1];
    }
    // 选择省市区
    if (this.regeion.length! === 3) {
      this.data.provinceCode = this.regeion[0];
      this.data.cityCode = this.regeion[1];
      this.data.districtCode = this.regeion[2];
      this.data.provinceName = this.regeionName[0];
      this.data.cityName = this.regeionName[1];
      this.data.districtName = this.regeionName[2];
    }
    // 添加公司信息
    if (this.i.id === 0) {
      // 获取父级组织id和name
      this.data.parentId = this.i.parentId || 0;
      this.data.parentName = this.i.parentName || '组织管理';
      this.http.put('/sys/dept/save', this.data).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          // 添加门店组织成功后，开启对应申报流程
          if (this.data.type === 2) {
            this.recordSave(res.deptId);
          }
          this.msgSrv.success('保存成功');
          this.cdr.detectChanges();
          this.modal.close(true);
        } else {
          this.modal.close(false);
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    } else {
      // 更新公司信息
      this.http.post('/sys/dept/update', this.data).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msgSrv.success('编辑成功');
          this.cdr.detectChanges();
          this.modal.close(true);
        } else {
          this.modal.close(false);
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    }
  }

  // 设置组织申报流程
  recordSave(deptId) {
    this.loading = true;
    this.http.put('/filing/record/save?deptId=' + deptId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('申报流程开启');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 整理地区列表
  generateTree(treeList) {
    if (treeList.length > 0) {
      for (const node of treeList) {
        if (node.children.length > 0) {
          this.generateTree(node.children);
        } else {
          // 设置叶节点标识
          node.isLeaf = true;
        }
      }
    }
    return treeList;
  }

  // 关闭添加面板
  close() {
    this.modal.destroy();
  }

}
