import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { AdminUserEditComponent } from './edit/edit.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { AdminUserEditPasswordComponent } from './edit-password/edit-password.component';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less'],
})
export class AdminUserComponent implements OnInit {
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    // { title: '序号', index: 'userId', type: 'checkbox' },
    { title: '用户名', index: 'username', i18n: 'user.list.name' },
    { title: '联系电话', index: 'mobile', i18n: 'st.mobile' },
    { title: '公司名称', index: 'deptName', i18n: 'user.list.deptName' },
    { title: '角色', index: 'roleName', i18n: 'user.list.roleName' },
    { title: '启用状态', index: 'userId', render: 'status', acl: { ability: ['sys:user:delete'] }, i18n: 'st.status' },
    {
      title: '操作',
      i18n: 'st.operate',
      className: 'table-actions',
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          type: 'modal',
          size: 500,
          component: AdminUserEditComponent,
          paramName: 'i',
          acl: { ability: ['sys:user:update'] },
          click: () => this.getList(),
        },
        {
          text: '修改密码',
          i18n: 'st.operate.editPassword',
          type: 'modal',
          size: 500,
          component: AdminUserEditPasswordComponent,
          paramName: 'i',
          acl: { ability: ['sys:user:password'] },
          click: () => this.getList(),
        },
        /* {
          text: '删除',
          pop: true,
          popTitle: '删除操作不可恢复，确认删除吗？',
          acl: { ability: ['sys:user:delete'] },
          click: (item: any) => this.delete(item.userId),
        } */
      ],
    },
  ];
  queryParams: any = {
    page: 1,
    limit: 10,
    username: '',
    mobile: '',
    deptName: ''
  };
  pageTotal: any = '';
  selectedRows: STData[] = []; // 选中删除数据
  loading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.loading = true;
    this.http.get('/sys/user/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
        this.cdr.detectChanges();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  add() {
    this.modal.static(AdminUserEditComponent, { i: { id: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 删除
  delete(userId) {
    this.loading = true;
    this.http.delete('/sys/user/delete?userIds=' + userId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.info('删除成功');
        this.getList();
      } else {
        this.msg.info(res.msg);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzTitle: '删除操作不可恢复，确认删除吗？',
      nzWidth: 500,
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.userId);
          })
          const ids = idList.join(',');
          this.delete(ids);
          resolve(true);
        }).catch(() => console.log('设置失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });

  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 更新启用状态
  changeStatus(event, userId) {
    this.loading = true;
    // true 开启
    if (event) {
      this.http.post('/sys/user/enable?userId=' + userId, '').subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msg.info("状态已启用");
          this.getList();
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      })
    } else {
      // false 禁用
      this.http.post('/sys/user/disable?userId=' + userId, '').subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msg.info("状态已禁用");
          this.getList();
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      })
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }
}
