import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService, ModalHelper } from '@delon/theme';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { AdminOrganizationEditComponent } from '../../../../routes/admin/organization/edit/edit.component';
import { AdminUserEditComponent } from '../../../../routes/admin/user/edit/edit.component';

@Component({
  selector: 'header-user',
  template: `
    <div
      class="alain-default__nav-item d-flex align-items-center px-sm"
      nz-dropdown
      nzPlacement="bottomRight"
      [nzDropdownMenu]="userMenu"
    >
      <nz-avatar [nzSrc]="settings.user.avatar" nzSize="small" class="mr-sm"></nz-avatar>
      {{ settings.user.username }}
    </div>
    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <div nz-menu class="width-sm">
        <!--
        <div nz-menu-item routerLink="/exception/trigger">
          <i nz-icon nzType="close-circle" class="mr-sm"></i>
          {{ 'menu.account.trigger' | translate }}
        </div> -->
        <div nz-menu-item (click)="userSet()">
          <i nz-icon nzType="user" class="mr-sm"></i>
          {{ 'menu.account.center' | translate }}
        </div>
        <div nz-menu-item (click)="orgnizationSet()" *ngIf="settings.user.deptType === 1">
          <i nz-icon nzType="setting" class="mr-sm"></i>
          {{ 'menu.account.settings' | translate }}
        </div>
        <li nz-menu-divider></li>
        <div nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout" class="mr-sm"></i>
          {{ 'menu.account.logout' | translate }}
        </div>
      </div>
    </nz-dropdown-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUserComponent {
  constructor(
    public settings: SettingsService,
    private router: Router,
    private cache: CacheService,
    private modal: ModalHelper,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  logout() {
    this.cache.remove('auth');
    this.cache.remove('selectedRegion');
    this.tokenService.clear();
    this.router.navigateByUrl(this.tokenService.login_url!);
  }

  // 设置当前组织信息
  orgnizationSet() {
    this.modal.static(AdminOrganizationEditComponent, { i: { id: 1, deptId: this.settings.user.deptId } }, 800).subscribe(() => {
    });
  }

  // 设置当前个人用户信息
  userSet() {
    this.modal.static(AdminUserEditComponent, { i: { id: 1, userId: this.settings.user.userId } }, 500).subscribe(() => {
    });
  }

}
