import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzFormatEmitEvent } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { FormGroup } from '@angular/forms';
import { ChildActivationEnd } from '@angular/router';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-role-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class AdminRoleEditComponent implements OnInit {
  loading = false;
  i: any;
  menus: any = []; // 权限列表
  // 添加角色信息
  data = {
    "deptId": 1,
    "roleName": "",
    "menuIdList": [],
    "deptIdList": []
  }
  // 编辑角色信息
  role = {
    "roleId": "",
    "deptId": 1,
    "roleName": "",
    "menuIdList": [],
    "deptIdList": []
  }
  allChecked = false;
  groupChecked: any = [false, false];
  groupIndeterminate: any = [false, false];
  indeterminate = false;

  // 设置所有权限选择事件
  updateAllChecked(): void {
    this.indeterminate = false;
    // 权限全选设置
    if (this.allChecked) {
      for (const group of this.menus) {
        group.checked = true; // 设置父级节点checked为true
        this.groupChecked[group.value] = true; // 设置父级节点绑定的数组元素值为true
        // 设置对应子节点元素checked为true
        group.childOptions = group.childOptions.map(item => {
          return {
            ...item,
            checked: true
          };
        })
      }
    } else {
      // 权限全不选设置
      for (const group of this.menus) {
        group.checked = false;
        this.groupChecked[group.value] = false;
        group.childOptions = group.childOptions.map(item => {
          return {
            ...item,
            checked: false
          };
        })
      }
    }
  }

  // 设置单个元素选择事件
  updateSingleChecked(): void {
    this.indeterminate = false;
    // 循环判断所有对应一级节点的选中状态
    for (const group of this.menus) {
      // 先判断一级节点是否选中，选中代表子节点全选，防止没有childOptions导致一级节点选中状态消失
      if (group.childOptions.length === 0) {
        if (group.checked) {
          this.groupChecked[group.value] = true;
          this.groupIndeterminate[group.value] = false;
        }
      } else {
        if (group.childOptions.every(item => !item.checked)) {
          this.groupChecked[group.value] = false;
          this.groupIndeterminate[group.value] = false;
          group.checked = false;
        } else if (group.childOptions.every(item => item.checked)) {
          this.groupChecked[group.value] = true;
          this.groupIndeterminate[group.value] = false;
          group.checked = true;
        } else {
          this.groupChecked[group.value] = false;
          this.groupIndeterminate[group.value] = true;
          group.checked = false;
          this.indeterminate = true;
        }
      }

    }
    // 判断所有权限的选中状态
    if (this.menus.every(item => !item.checked)) {
      this.allChecked = false;
    } else if (this.menus.every(item => item.checked)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }

  // 父级节点选中事件
  updateGroupChecked(event, groupId) {
    for (const group of this.menus) {
      // 获取对应父节点，判断选择框状态
      if (group.value === groupId) {
        this.groupIndeterminate[group.value] = false;
        // 根据event状态判断是否选中
        // event为true设置父节点和对应子节点全部选中
        if (event) {
          group.checked = true;
          group.childOptions = group.childOptions.map(item => {
            return {
              ...item,
              checked: true
            };
          })
        } else {
          // event为false设置父节点和对应子节点全部未选中
          group.checked = false;
          group.childOptions = group.childOptions.map(item => {
            return {
              ...item,
              checked: false
            };
          })
        }
      }
    }
    // 判断所有权限的选中状态
    if (this.menus.every(item => !item.checked)) {
      this.allChecked = false;
    } else if (this.menus.every(item => item.checked)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private modalHelper: ModalHelper,
    private dialogService: DialogService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.menus = await this.getAuthList();
    if (this.i.id !== 0) {
      this.getRoleInfo();
    }
    this.loading = false;
  }

  // 获取角色信息
  getRoleInfo() {
    this.http.get(`/sys/role/info/${this.i.roleId}`).subscribe(async res => {
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data = res.role;
        this.role.roleId = res.role.roleId;
        this.role.deptId = res.role.deptId;
        this.role.deptIdList = res.role.deptIdList;
        // 处理权限列表选中
        this.data.menuIdList.map(item => {
          this.menus.map(group => {
            // 如果当前菜单有子菜单，根据子菜单列表设置选中状态
            if (group.childOptions.length > 0) {
              group.childOptions.map(child => {
                if (child.value === item) {
                  child.checked = true;
                }
              })
            } else {
              // 当前菜单没有子列表，根据当前菜单判断选中状态
              if (group.value === item) {
                group.checked = true;
                this.groupChecked[group.value] = true;
              }
            }
          })
        })
        // 根据子菜单选中状况更新父级菜单选中状态
        this.updateSingleChecked();
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  save() {
    this.loading = true;
    // 新建menuList数组存储已选权限，this.data.menuIdList为编辑前已选列表，未清空导致重复
    const menuList = new Array();
    // 获取选中权限列表data.menuIdList
    this.menus.map(item => {
      if (item.checked) {
        // 添加父级权限id
        if (!menuList.includes(item.parentId)) {
          menuList.push(item.parentId);
        }
        // 父节点选中，所包含子节点全部选中放入列表
        menuList.push(item.value);
        item.childOptions.map(child => {
          menuList.push(child.value);
        })
      } else {
        // 父节点未选中，循环子节点列表，若有子节点选中，则父节点和选中子节点存入
        if (!item.childOptions.every(child => !child.checked)) {
          if (!menuList.includes(item.parentId)) {
            menuList.push(item.parentId);
          }
          menuList.push(item.value);
          item.childOptions.map(child => {
            if (child.checked) {
              menuList.push(child.value);
            }
          })
        }
      }
    });
    // 添加角色信息
    if (this.i.id === 0) {
      this.data.menuIdList = menuList;
      this.http.put('/sys/role/save', this.data).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msgSrv.success('保存成功');
          this.modal.close(true);
        } else {
          this.modal.close(false);
        }

      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    } else {
      // 编辑角色信息
      this.role.roleName = this.data.roleName;
      this.role.menuIdList = menuList;
      this.http.post('/sys/role/update', this.role).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msgSrv.success('编辑成功');
          this.modal.close(true);
        } else {
          this.modal.close(false);
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    }
  }

  // 获取初始化权限菜单列表
  getAuthList() {
    return new Promise((resolve, reject) => {
      this.http.get<any>(`/sys/menu/list`).subscribe(res => {
        this.dialogService.getErrorCode(res);
        if (res.msg === 'success') {
          const menus = new Array();
          // 获取一级菜单列表，type=1
          const menusOne = res.menuList.filter(item => item.type === 1);
          // 循环获取一级菜单的权限子菜单列表
          for (const menu of menusOne) {
            const childMenuOptions: any = [];
            for (const child of res.menuList) {
              if (menu.menuId === child.parentId) {
                childMenuOptions.push({ label: child.name, value: child.menuId, parentId: child.parentId, checked: false });
              }
            }
            menus.push({ label: menu.name, value: menu.menuId, parentId: menu.parentId, checked: false, childOptions: childMenuOptions });
          }
          resolve(menus);
        }
      }, error => {
        resolve(error.message);
      });
    });

  }

  close() {
    this.modal.destroy();
  }
}
