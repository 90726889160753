import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private modalService: NzModalService,
  ) { }

  // 验证接口返回数据
  getErrorCode(data) {
    let flag = true;
    const codes = [100, 101, 102, 500];
    if (codes.includes(data.code)) {
      flag = false;
      this.modalService.warning({
        nzTitle: data.msg,
        nzWrapClassName: 'vertical-center-modal',
      })
    }
    return flag;
  }
}
