import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-user-edit-password',
  templateUrl: './edit-password.component.html',
})
export class AdminUserEditPasswordComponent implements OnInit {
  record: any = {};
  i: any;
  passwordFlag = false;
  loading = false;

  params: any = {
    password: '',
    confirmPassword: ''
  };

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private modalHelper: ModalHelper,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void { }


  // 修改密码
  updatePassword() {
    this.loading = true;
    this.http.post(`/sys/user/setPassword?password=${this.params.password}&confirmPassword=${this.params.confirmPassword}&userId=${this.i.userId}`).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('保存成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 验证新密码和确认密码
  checkPassword() {
    if (!this.params.confirmPassword || !this.params.password) {
      this.passwordFlag = false;
      return;
    }
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.params.confirmPassword !== this.params.password) {
      this.passwordFlag = true;
    } else {
      this.passwordFlag = false;
    }
  }

  close() {
    this.modal.destroy();
  }
}
