import { MockRequest } from '@delon/mock';

const list: any[] = [];
const total = 50;
const roleList: any[] = [
  {id: 1, roleName: '超级管理员', roleAuth: '所有权限', createdAt: new Date(`2019-11-01`) },
  {id: 2, roleName: '公司管理员', roleAuth: '16项权限', createdAt: new Date(`2019-11-02`) },
  {id: 3, roleName: '审批人员', roleAuth: '14项权限', createdAt: new Date(`2019-11-02`) },
  {id: 4, roleName: '公司普通用户', roleAuth: '12项权限', createdAt: new Date(`2019-11-04`) },
  {id: 5, roleName: '子公司管理员', roleAuth: '9项权限', createdAt: new Date(`2019-11-05`) },
  {id: 6, roleName: '子公司普通用户', roleAuth: '6项权限', createdAt: new Date(`2019-11-06`) },
];

for (let i = 0; i < total; i += 1) {
  list.push({
    id: i + 1,
    disabled: i % 6 === 0,
    href: 'https://ant.design',
    avatar: [
      'https://gw.alipayobjects.com/zos/rmsportal/eeHMaZBwmTvLdIwMfBpg.png',
      'https://gw.alipayobjects.com/zos/rmsportal/udxAbMEhpwthVVcjLXik.png',
    ][i % 2],
    no: `90x101234567${i}`,
    cname: [
      '肯德基有限公司上海总公司',
      '肯德基有限公司北京总公司',
      '肯德基有限公司深圳总公司',
      '肯德基有限公司广州总公司',
      '肯德基有限公司杭州总公司',
      '肯德基有限公司郑州总公司',
      '肯德基有限公司武汉总公司',
      '肯德基有限公司西安总公司',
      '肯德基有限公司成都总公司',
      '肯德基有限公司重庆总公司',
      '肯德基有限公司南京总公司',
      '肯德基有限公司青岛总公司',
      '肯德基有限公司天津总公司',
      '肯德基有限公司天津总公司',
    ][i % 14],
    address: [
      '上海市浦东新区南京西路220弄',
      '北京市海淀区金山路1002号',
      '深圳市宝安区宝安路192号',
      '广州市天河区天河路102号',
    ][i % 4],
    name: ['李晓米', '张雨绮', '方圆', '王玉'][i % 4],
    phone: ['15982909992', '18939098294', '18637866637', '15981927492'][i % 4],
    cnums: ['6', '3', '2', '9'][i % 4],
    isStart: [true, true, false, true][i % 4],
    roleName: [],
    roleAuth: [],
    title: `一个任务名称 ${i}`,
    owner: '曲丽丽',
    description: '这是一段描述',
    callNo: Math.floor(Math.random() * 1000),
    status: Math.floor(Math.random() * 10) % 4,
    updatedAt: new Date(`2017-07-${Math.floor(i / 2) + 1}`),
    createdAt: new Date(`2017-07-${Math.floor(i / 2) + 1}`),
    progress: Math.ceil(Math.random() * 100),
  });
}


function genData(params: any) {
  let ret = [...list];
  const pi = +params.pi;
  const ps = +params.ps;
  const start = (pi - 1) * ps;

  if (params.no) {
    ret = ret.filter(data => data.no.indexOf(params.no) > -1);
  }

  return { total: ret.length, list: ret.slice(start, ps * pi) };
}

function genRoleData(params: any) {
  let ret = [...roleList];
  const pi = +params.pi;
  const ps = +params.ps;
  const start = (pi - 1) * ps;

  if (params.no) {
    ret = ret.filter(data => data.no.indexOf(params.no) > -1);
  }

  return { total: ret.length, list: ret.slice(start, ps * pi) };
}

function saveData(id: number, value: any) {
  const item = list.find(w => w.id === id);
  if (!item) return { msg: '无效用户信息' };
  Object.assign(item, value);
  return { msg: 'ok' };
}

export const USERS = {
  '/user': (req: MockRequest) => genData(req.queryString),
  '/user/:id': (req: MockRequest) => list.find(w => w.id === +req.params.id),
  'POST /user/:id': (req: MockRequest) => saveData(+req.params.id, req.body),
  '/user/current': {
    name: 'Cipchk',
    avatar: 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png',
    userid: '00000001',
    email: 'cipchk@qq.com',
    signature: '海纳百川，有容乃大',
    title: '交互专家',
    group: '蚂蚁金服－某某某事业群－某某平台部－某某技术部－UED',
    tags: [
      {
        key: '0',
        label: '很有想法的',
      },
      {
        key: '1',
        label: '专注撩妹',
      },
      {
        key: '2',
        label: '帅~',
      },
      {
        key: '3',
        label: '通吃',
      },
      {
        key: '4',
        label: '专职后端',
      },
      {
        key: '5',
        label: '海纳百川',
      },
    ],
    notifyCount: 12,
    country: 'China',
    geographic: {
      province: {
        label: '上海',
        key: '330000',
      },
      city: {
        label: '市辖区',
        key: '330100',
      },
    },
    address: 'XX区XXX路 XX 号',
    phone: '你猜-你猜你猜猜猜',
  },
  'POST /user/avatar': 'ok',
  'POST /login/account': (req: MockRequest) => {
    const data = req.body;
    if (!(data.userName === 'admin' || data.userName === 'user') || data.password !== 'ng-alain.com') {
      return { msg: `Invalid username or password（admin/ng-alain.com）` };
    }
    return {
      msg: 'ok',
      user: {
        token: '123456789',
        name: data.userName,
        email: `${data.userName}@qq.com`,
        id: 10000,
        time: +new Date(),
      },
    };
  },
  'POST /register': {
    msg: 'ok',
  },
  '/role': (req: MockRequest) => genRoleData(req.queryString),
};
