import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-organization-third',
  templateUrl: './third.component.html',
})
export class AdminOrganizationThirdComponent implements OnInit {
  loading = true;
  thirdUrl: any = '';
  deptId = '';
  constructor(
    private http: _HttpClient,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    ) {
      this.thirdUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.thirdUrl);
    }

  ngOnInit() {
    this.deptId = this.route.snapshot.queryParams.deptId;
    this.getThirdOrgnizationUrl();
   }

  // 获取第三方企业返回信息url
  getThirdOrgnizationUrl() {
    this.loading = true;
    this.http.get('/sys/dept/thirdOrgUrl?deptId=' + this.deptId).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.thirdUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.data);
      }
    }, error => {
      this.loading = false;
      console.log('httpError', error);
    });
  }


}
