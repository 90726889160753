import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminOrganizationComponent } from './organization/organization.component';
import { AdminRoleComponent } from './role/role.component';
import { AdminUserComponent } from './user/user.component';
import { AdminOrganizationThirdComponent } from './organization/third/third.component';

const routes: Routes = [

  { path: 'organization', component: AdminOrganizationComponent },
  { path: 'role', component: AdminRoleComponent },
  { path: 'user', component: AdminUserComponent },
  { path: 'third', component: AdminOrganizationThirdComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
