import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, SettingsService } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { DialogService } from 'src/app/service/dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
  // 待办事项
  todoRows = [];
  @ViewChild('todost', { static: false }) st: STComponent;
  todoColumns: STColumn[] = [
    { title: '', index: 'descr', render: 'title', className: 'todo-title', },
    {
      title: '',
      buttons: [
        {
          text: '查看',
          type: 'link',
          click: record => this.viewFiling(record),
        },
      ]
    }
  ];
  todoPageTotal = 0;
  todoParams: any = {
    page: 1,
    limit: 5,
  };
  todoLoading = false;

  // 最新动态数据
  newsRows = [];
  @ViewChild('newst', { static: false }) newst: STComponent;
  newsColumns: STColumn[] = [
    { title: '子公司名称', index: 'deptName', i18n: 'dashboard.group.news.deptName' },
    { title: '税号', index: 'taxCode', i18n: 'dashboard.group.news.taxCode' },
    { title: '时间', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD  HH:mm:ss', i18n: 'dashboard.group.news.createTime' },
    { title: '进度动态', index: 'descr', i18n: 'dashboard.group.news.progress' },
  ];
  newsPageTotal = 0;
  newsParams: any = {
    page: 1,
    limit: 4,
  };
  newsLoading = false;

  // 公告数据
  saleTabs: any[] = [{ key: 'announcement', id: 1, show: true }, { key: 'upgrade', id: 2 }, { key: 'other', id: 3 }];
  noticeListData: any[] = [];
  systemListData: any[] = [];
  otherListData: any[] = [];
  curDate = new Date();
  thisDate = new Date().getDate();
  data: any = {};
  loading = false;
  chart: any[] = [
    { title: 'dashboard.group.statistics.upload', percent: 0, color: '#E0301E' },
    { title: 'dashboard.group.statistics.reportConfirm', percent: 0, color: '#E0301E' },
    { title: 'dashboard.group.statistics.declare', percent: 0, color: '#E0301E' },
    { title: 'dashboard.group.statistics.deduction', percent: 0, color: '#E0301E' },
  ];
  // 节点对应日期数值
  nodeDate: any = {
    uploadDate: '',
    reportConfirmDate: '',
    declareDate: '',
    deductionDate: '',
  }
  // 日历提醒列表
  listDataMap = {
    uploadDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.uploadDate' },
    ],
    reportConfirmDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.reportConfirmDate' },
    ],
    declareDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.declareDate' },
    ],
    deductionDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.deductionDate' },
    ]
  };
  cMonth: any = '';
  constructor(
    private http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    public settings: SettingsService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.loading = true;
    await this.getNoticeList();
    await this.getNewsList();
    await this.getTodoList();
    await this.getDashboardData();
    // 初始化申报统计日历月份
    this.cMonth = new Date(this.settings.layout.globalDate).getMonth();
    this.loading = false;
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 获取公告管理列表
  getNoticeList() {
    this.http.get('/sys/announcement/list').subscribe(data => {
      const flag = this.dialogService.getErrorCode(data);
      if (flag) {
        if (data.page.list.length > 0) {
          const noticeTypes = [1, 2, 3, 6];
          this.noticeListData = data.page.list.filter(i => noticeTypes.includes(i.type) === true).slice(0, 7);
          this.systemListData = data.page.list.filter(i => i.type === 5).slice(0, 7);
          this.otherListData = data.page.list.filter(i => i.type === 4).slice(0, 7);
        }
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 获取集团总览数据
  getDashboardData() {
    this.http.get('/filing/dashboard').subscribe(data => {
      const flag = this.dialogService.getErrorCode(data);
      if (flag) {
        if (data.data) {
          this.data = data.data;
          // 初始化纳税申报饼状图统计数据
          this.chart[0].percent = this.data.uploadPercent;
          this.chart[1].percent = this.data.confirmPercent;
          this.chart[2].percent = this.data.declarePercent;
          this.chart[3].percent = this.data.deductionPercent;
          for (const item of this.chart) {
            if (item.percent >= 0.6 && item.percent < 0.8) {
              item.color = '#FFB600';
            } else if (item.percent >= 0.8) {
              item.color = '#46CE5E';
            } else {
              item.color = '#E0301E';
            }
          }

          // 初始化申报节点日期和状态
          if (data.data.filingNode) {
            // 节点时间
            this.nodeDate.uploadDate = data.data.filingNode.uploadDate;
            this.nodeDate.reportConfirmDate = data.data.filingNode.reportConfirmDate;
            this.nodeDate.declareDate = data.data.filingNode.declareDate;
            this.nodeDate.deductionDate = data.data.filingNode.deductionDate;
            // 节点状态
            this.listDataMap.uploadDate[0].color = this.chart[0].color;
            this.listDataMap.reportConfirmDate[0].color = this.chart[1].color;
            this.listDataMap.declareDate[0].color = this.chart[2].color;
            this.listDataMap.deductionDate[0].color = this.chart[3].color;
          }
        }
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 待办事项列表
  getTodoList() {
    this.todoLoading = true;
    this.http.get('/sys/notice/list', this.todoParams).subscribe(data => {
      this.todoLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.code === 0) {
        this.todoRows = data.page.list;
        this.todoParams.page = data.page.currPage;
        this.todoParams.limit = data.page.pageSize;
        this.todoPageTotal = data.page.totalCount;
      }
    }, error => {
      this.todoLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 查看待办-操作记录详情
  viewFiling(record) {
    this.router.navigate(['/filing/record/info'], { queryParams: { filingId: record.filingId, deptId: record.deptId } });
  }

  // 最新动态列表
  getNewsList() {
    this.newsLoading = true;
    this.http.get('/filing/operatelog/list', this.newsParams).subscribe(data => {
      this.newsLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.code === 0) {
        this.newsRows = data.page.list;
        this.newsParams.page = data.page.currPage;
        this.newsParams.limit = data.page.pageSize;
        this.newsPageTotal = data.page.totalCount;
      }
    }, error => {
      this.newsLoading = false;
      console.log('HTTP Error', error);
    });
  }

  calendarChange(event) {
    this.thisDate = event.getDate();
  }

  // 待办事项分页
  todoPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.todoParams.page !== event.pi) {
        this.todoParams.page = event.pi;
        this.getTodoList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.todoParams.limit !== event.ps) {
        this.todoParams.limit = event.ps;
        this.todoParams.page = 1;
        this.getTodoList();
      }
    }
  }

  // 最新动态分页
  newsPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.newsParams.page !== event.pi) {
        this.newsParams.page = event.pi;
        this.getNewsList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.newsParams.limit !== event.ps) {
        this.newsParams.limit = event.ps;
        this.newsParams.page = 1;
        this.getNewsList();
      }
    }
  }

}
